import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import Layout from "components/Layout/de"
import partnershipImg from "../partnershipImg.jpg"
import BasicPartnerLogo from "../Basic.png"
import GoldPartnerLogo from "../Gold.png"
import Image from "components/Image/index"

export default function(props) {
  const header = {
    x: 44,
    y: 33,
    text: "Werde ein MoonVision Certified.Partner",
    description:
      "Haben Sie eigene Ideen oder sind Sie Experte aus einer anderen Industrie? Profitieren Sie von der Partnerschaft mit MoonVision und werden Sie unser MoonVision Certified.Partner.",
    image: partnershipImg,
  }
  return (
    <Layout header={header} location={props.location}>
      <Container>
        <Row className="pt-5">
          <Col md={{ span: 8, offset: 2 }} className="text-center">
            <h2 style={{ fontSize: "2rem" }}>
              Certified.Partner Anlagenbauer, Mikroskop- oder Kamera-Hersteller
            </h2>
            <p>Profitieren Sie von einem europaweiten Netzwerk</p>
            <p>
              Die Zufriedenheit unserer Kunden ist unser höchstes Ziel. Als
              Softwareanbieter setzen wir höchste Qualitätsansprüche an uns
              selbst und an unsere Hardwarepartner. Werden Sie Certified
              Hardware-Partner und begeistern Sie gemeinsam mit uns
              internationale Kunde für effiziente hochautomatisierte Prozesse in
              der Fertigung, Qualitätssicherung sowie Produkt- und
              Prozessüberwachung.
            </p>
          </Col>
        </Row>
        <Row className="py-5 text-center">
          <Col md="4">
            <div className="bluebox">
              <h6>Expertise</h6>
              <p>
                Profitieren Sie von unserer langjährigen Erfahrung und unserem
                Wissen als Technologieführer in den Bereichen IoT, KI/AI, Deep
                Learning, Computer Vision, usw.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <h6>Gesamtlösung</h6>
              <p>
                Schaffen Sie intelligente Gesamtlösungen und steig fern dadurch
                Ihren Umsatz.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <h6>Marketingunterstützung</h6>
              <p>
                Wir unterstützen Sie bei Präsentation Ihrer Produkte in
                Verbindung mit MoonVision als Gesamtlösung mit
                individualisierten Marketingmaterialien.
              </p>
            </div>
          </Col>
          <Col md={{ span: 4, offset: 2 }}>
            <div className="bluebox">
              <h6>Rollout-Prozesse</h6>
              <p>
                Wir ermöglichen effiziente Prozesse und schaffen die optimale
                Lösung für Ihre Kunden.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="bluebox">
              <h6>Vertriebsschulung</h6>
              <p>
                Mit welchen Argumenten Sie Ihre Kunden begeistern können,
                erfahren Sie in regelmäßigen Vertriebsschulungen.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="py-5">
          <Col md="6">
            <div className="grey-background p-2" style={{ height: "100%" }}>
              <Image
                filename={BasicPartnerLogo}
                alt="basic partner logo"
                className="my-3"
              />
              <ul>
                <li>MoonVision Zertifikat und Zertifizierungs-Logo</li>
                <li>
                  MoonVision Toolbox Testinstanz für regelmäßiges und
                  selbstständiges Testen
                </li>
                <li>Einschulung in die MoonVision Toolbox</li>
              </ul>
              <Button>Jetzt Partner werden</Button>
            </div>
          </Col>
          <Col md="6">
            <div className="grey-background p-2" style={{ height: "100%" }}>
              <Image
                filename={GoldPartnerLogo}
                alt="gold partner logo"
                className="my-3"
              />
              <ul>
                <li>MoonVision Zertifikat und Zertifizierungs-Logo</li>
                <li>
                  MoonVision Toolbox Testinstanz für regelmäßiges und
                  selbstständiges Testen mit Anwendung eines Demo-Modells für
                  Testzwecke
                </li>
                <li>
                  Unterstützung bei der Vermarktung über diverse Marketingkanäle
                  sowie Aufnahme in Referenzlisten
                </li>
                <li>
                  Remote Technical Support für Anlagen-Inbetriebnahmen im Umfang
                  von max. 15 h pro Jahr
                </li>
              </ul>
              <Button>Jetzt Partner werden</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
